import React from "react"
import { UnorderedList, ListItem } from "@chakra-ui/layout"
import { Tag } from "@chakra-ui/tag"

interface ISimpleList {
    items: Array<string>;
}

const SimpleList: React.FC<ISimpleList> = ({items}) => {
    return (
        <UnorderedList
            styleType="none"
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            margin={1.5}
            padding={0}
        >
            {
                items
                    .map((item, i) => (
                        <ListItem
                            key={i}
                            margin={2}
                            fontSize="md"
                        >
                            <Tag>
                                {item}
                            </Tag>
                        </ListItem>
                    ))
            }
        </UnorderedList>
    )
}

export default SimpleList
