import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Button } from "@chakra-ui/button"
import { Tooltip } from "@chakra-ui/tooltip"
import { useClipboard } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import { useColorModeValue as mode } from "@chakra-ui/color-mode";
import { Heading, Box, Grid, Stack, Text, Divider, Flex, Link, UnorderedList, ListItem } from "@chakra-ui/layout"

import Layout from "../../components/layout"
import SimpleList from "../../components/simple-list"
import ProjectCard from "../../components/project-card"


const tools = ["VS Code", "Visual Studio 2019", "AWS", "FireFox Developer Edition", "Postman", "cURL", "Git", "Bash", "Sony XM4s", "Macbook Pro", "Figma", "Affinity Designer", "PlayStation 4", "Water", "Pizza"]


const TellMeMore: React.FC = () => {
    const { hasCopied, onCopy } = useClipboard('syed@notenoughpizza.dev')
    const query = useStaticQuery(graphql`
        query {
            allContentfulProject {
                edges {
                    node {
                        name
                        description
                        logo {
                            title
                            file {
                                url
                            }
                        }
                    }
                }
            }
            allContentfulLanguage(sort: {fields: [createdAt]}) {
                edges {
                node {
                    name
                    type
                }
                }
            }
        }
    `);
    
    return (
    <Layout
        title="More about me 🧐"
        icon="🔬"
    >
        <Flex as="section"
            flexDirection="column"
            placeContent="center"
            width="1080px"
            maxWidth="100%"
            minHeight="100vh"
            marginLeft="auto"
            marginRight="auto"
            paddingTop={6}
            paddingBottom={6}
            paddingLeft={3}
            paddingRight={3}
        >
            <Stack>
                <Heading as="h1"
                    fontSize="clamp(24px, calc(16px + 2vw), 40px)"
                >
                    I enjoy writing software that scales. I am currently in love with pizza and also working remotely as a freelance software developer
                </Heading>
                <UnorderedList
                    styleType="none"
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    marginRight={1.5}
                    marginTop={1.5}
                    marginBottom={1.5}
                    padding={0}
                >
                    <Link onClick={onCopy}>
                        <ListItem
                            margin={2}
                            fontSize="md"
                        >
                            <Tooltip hasArrow
                                label="syed@notenoughpizza.dev - Click to copy"
                                bg="gray.300"
                                color="black"
                            >
                                <Button variant="ghost"    
                                        paddingLeft={6}
                                        paddingRight={6}
                                        paddingTop={1}
                                        paddingBottom={1} 
                                        borderRadius="3xl"
                                        background={mode("gray.100", "whiteAlpha.300")}
                                        _hover={{bg: mode("gray.200", "whiteAlpha.100")}}
                                        _active={{bg: mode("gray.200", "whiteAlpha.100")}}
                                        fontSize="sm"
                                    >
                                        Email
                                </Button>
                            </Tooltip>
                        </ListItem>
                    </Link>
                    <Link href="https://github.com/umartayyab" isExternal>
                        <ListItem
                            margin={2}
                            fontSize="md"
                        >
                            <Button variant="ghost"    
                                paddingLeft={4}
                                paddingRight={4}
                                paddingTop={1}
                                paddingBottom={1} 
                                borderRadius="3xl"
                                background={mode("gray.100", "whiteAlpha.300")}
                                _hover={{bg: mode("gray.200", "whiteAlpha.100")}}
                                fontSize="sm"
                            >
                                    Github<ExternalLinkIcon mx="8px" />
                            </Button>
                        </ListItem>
                    </Link>
                    <Link href="https://devpost.com/umartayyab" isExternal>
                        <ListItem
                            margin={2}
                            fontSize="md"
                        >
                            <Button variant="ghost"    
                                paddingLeft={4}
                                paddingRight={4}
                                paddingTop={1}
                                paddingBottom={1} 
                                borderRadius="3xl"
                                background={mode("gray.100", "whiteAlpha.300")}
                                                                _active={{bg: mode("gray.200", "whiteAlpha.100")}}
                                fontSize="sm"
                            >
                                    Devpost<ExternalLinkIcon mx="8px" />
                            </Button>
                        </ListItem>
                    </Link>
                </UnorderedList>
            </Stack>

            {/* <Heading as="h2"
                fontSize="clamp(calc(24px * 0.75), calc((calc(16px + 2vw)) * 0.75), calc(40px * 0.75))"
                marginBottom={2}
            >
                Currently active projects
                <Text as="span"> </Text>
                <Text as="span" role="img" aria-label="Man dancing emoji"
                    background="transparent"
                >
                    🕺
                </Text>
            </Heading> */}
            <Grid as="div"
                templateColumns={{base: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))"}}
                gridRowGap={6}
                gridColumnGap={8}
                placeContent="center"
                marginTop={8}
            >
                {
                    query.allContentfulProject.edges
                    .map(project => (
                        <ProjectCard
                            key={project.node.name}
                            title={project.node.name}
                            description={project.node.description}
                            logo={project.node.logo.file.url}
                        ></ProjectCard>
                    ))
                }
            </Grid>
            <Divider
                marginTop={6}
                marginBottom={6}
            ></Divider>
            <Grid as="div"
                templateColumns={{base: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))"}}
                gridRowGap={6}
                gridColumnGap={8}
                placeContent="center"
            >
                <Stack>
                    <Heading as="h2"
                        fontSize="clamp(calc(24px * 0.75), calc((calc(16px + 2vw)) * 0.75), calc(40px * 0.75))"
                        marginBottom={2}
                    >
                        Languages I speak
                        <Text as="span"> </Text>
                        <Text as="span" role="img" aria-label="Input symbol for letters emoji"
                            background="transparent"
                        >
                            🔤
                        </Text>
                    </Heading>
                    <Grid as="div"
                        templateColumns="repeat(1, minmax(0, 1fr))"
                        gridRowGap={6}
                        gridColumnGap={8}
                        placeContent="center"
                    >
                        <Box>
                            <Text as="h3"
                                fontSize={{base: "l", md: "2xl", lg: "2xl"}}
                                fontWeight={500}
                            >
                                To humans
                                <Text as="span"> </Text>
                                <Text as="span" role="img" aria-label="Man and woman emoji"
                                    background="transparent"
                                >
                                    👨👩
                                </Text>
                            </Text>
                            <SimpleList items={
                            query.allContentfulLanguage.edges
                                .filter(((l) => l.node.type === 'Human'))
                                .map((n) => n.node.name)
                            }  />
                        </Box>
                        <Box>
                            <Text as="h3"
                                fontSize={{base: "l", md: "2xl", lg: "2xl"}}
                                fontWeight={500}
                            >
                                To machines 
                                <Text as="span"> </Text>
                                <Text as="span" role="img" aria-label="Robot face emoji"
                                    background="transparent"
                                >
                                    🤖
                                </Text>
                            </Text>
                            <SimpleList items={
                            query.allContentfulLanguage.edges
                                .filter(((l) => l.node.type === 'Computer'))
                                .map((n) => n.node.name)
                            } 
                            />
                        </Box>
                    </Grid>
                </Stack>
                <Stack>
                    <Heading as="h2"
                        fontSize="clamp(calc(24px * 0.75), calc((calc(16px + 2vw)) * 0.75), calc(40px * 0.75))"
                        marginBottom={2}
                    >
                        Tools I use 
                        <Text as="span"> </Text>
                        <Text as="span" role="img" aria-label="Hammer and spanner emoji"
                            background="transparent"
                        >
                             🛠
                        </Text>
                    </Heading>
                    <SimpleList items={tools} />
                </Stack>
            </Grid>
            
            {/* <Divider
                marginTop={6}
                marginBottom={6}
            ></Divider> */}
            {/* <Heading as="h2"
                fontSize="clamp(calc(24px * 0.75), calc((calc(16px + 2vw)) * 0.75), calc(40px * 0.75))"
                marginBottom={2}
            >
                Other interests
                <Text as="span"> </Text>
                <Text as="span" role="img" aria-label="Man dancing emoji"
                    background="transparent"
                >
                    🕺
                </Text> */}
                {/* 
                    Sports
                    Games
                    YouTubers
                    Books
                    Pizzas
                */}
            {/* </Heading> */}
            
            {/* <SimpleGrid minChildWidth={120} spacing="40px">
                <Box>
                    <Text as="h3"
                        fontSize={{base: "l", md: "2xl", lg: "2xl"}}
                        fontWeight={500}
                    >
                        Gaming
                        <Text as="span"> </Text>
                        <Text as="span" role="img" aria-label="Pizza emoji"
                            background="transparent"
                        >
                            🎮
                        </Text>
                    </Text>
                    <SimpleList items={machineLanguages} />
                </Box>
            </SimpleGrid> */}
        </Flex>
    </Layout>
)}

export default TellMeMore
