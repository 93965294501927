import React from "react"
import { Flex, Text, Box } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/button"
import { Image } from "@chakra-ui/image"
import { Link } from "gatsby"


interface IProjectCard {
    title: string;
    description: string;
    logo: string;
}

const ProjectCard: React.FC<IProjectCard> = ({title, description, logo}) => {

    return (
        <Flex as="div" role="group"
            flexDirection="row"
        >
            <Image 
                src={logo}
                boxSize={24}
                marginRight={2}
                borderRadius="lg"
            />
            <Flex as="div"
                flexDirection="row"
                alignItems="center"
                justifyContent="start"
            >
                <Box as="div"
                    marginRight={1}
                >
                    <Text
                        fontWeight="bold"
                        fontSize="lg"
                    >
                        {title}
                    </Text>
                    <Text
                        fontSize="clamp(12px, 10.8px + 0.25vw, 14px)"
                    >
                        {description}
                    </Text>
                </Box>
                <Link to={`/tell-me-more/${title.includes('Çaquina') ? 'caquina-beauty': title.toLowerCase().split(' ').join('-')}`} >
                    <Button
                        display="inline-flex"
                        color="white"
                        fontSize="sm"
                        height="8"
                        width="auto"
                        minWidth="8"
                        borderRadius="3xl"
                        paddingLeft="6"
                        paddingRight="6"
                        paddingTop="0"
                        paddingBottom="0"
                        background="blackAlpha.900"
                        _hover={{bg: "blackAlpha.800"}}
                        _active={{bg: "blackAlpha.800"}}
                    >
                        More
                    </Button>
                </Link>
            </Flex>
        </Flex>
    )
}

export default ProjectCard
